import { Button, Result } from 'antd'
import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { SmileOutlined } from '@ant-design/icons'
import './style.less'
import { httpGet } from '../../services/http'

export default function GuideCompletePage() {
    useEffect(() => {
        httpGet('/landing/article/guide-complete/read')
    }, [])

    return (
        <>
            <div id="progressBarContainer">
                <div
                    id="progressBar"
                    style={{
                        transform: 'scale(1,1)',
                        opacity: 1,
                    }}
                />
            </div>
            <Result
                icon={<SmileOutlined />}
                title="Great, we have completed all the steps!"
                subTitle='You can always return to this guide from "User Guide" on the Settings Page!'
                extra={
                    <Link to="/app/">
                        <Button type="primary">Start Using App</Button>
                    </Link>
                }
            />
        </>
    )
}
